import axios, { AxiosResponse } from 'axios';
import { Config } from '@/aggregator/config/env';

const merchantApiUrl = Config.MERCHANT_API_URL;
const carrierApiUrl = Config.CARRIER_API_URL;

const taskConfig = {
  headers: Config.TASK_API_HEADERS,
};

export const listMerchantLocationsServiceByCarrier = async (
  carrierUuid: string,
  programUuid?: string,
): Promise<AxiosResponse> => {
  let url: string;

  if (programUuid !== undefined) {
    url = `${merchantApiUrl}/merchants/locations?carrierUuid=${carrierUuid}&programUuid=${programUuid}`;
  } else {
    url = `${merchantApiUrl}/merchants/locations?carrierUuid=${carrierUuid}`;
  }
  return await axios
    .get(url)
    .then((response) => response)
    .catch((error) => error);
};

export const listMerchantsService = async (): Promise<AxiosResponse> => {
  return await axios
    .get(`${merchantApiUrl}/staff/merchants`)
    .then((response) => response)
    .catch((error) => error);
};

export const getPaymentIntegrationStatus = async (
  merchantUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${merchantApiUrl}/staff/merchants/${merchantUuid}/payment-integration-status`,
    )
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

//////////////////// CARRIER ENDPOINTS ////////////////////
//////////////////// CARRIER ENDPOINTS ////////////////////
//////////////////// CARRIER ENDPOINTS ////////////////////
//////////////////// CARRIER ENDPOINTS ////////////////////
//////////////////// CARRIER ENDPOINTS ////////////////////

export const listCarriersService = async ({
  programUuid,
}: {
  programUuid: string;
}): Promise<AxiosResponse> => {
  return await axios
    .get(`${carrierApiUrl}/carriers?program_uuid=${programUuid}`)
    .then((response) => response);
};

export const getCarrierPaymentIntegrationStatus = async (
  carrierUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${carrierApiUrl}/staff/carriers/${carrierUuid}/payment-integration-status`,
    )
    .then((response) => response)
    .catch((error) => error);
};

export const getProgramPaymentIntegrationStatus = async (
  programUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .get(
      `${carrierApiUrl}/staff/programs/${programUuid}/payment-integration-status`,
    )
    .then((response) => response)
    .catch((error) => error);
};

///////////////// TASK SCHEDULE ENDPOINTS /////////////////
///////////////// TASK SCHEDULE ENDPOINTS /////////////////
///////////////// TASK SCHEDULE ENDPOINTS /////////////////
///////////////// TASK SCHEDULE ENDPOINTS /////////////////
///////////////// TASK SCHEDULE ENDPOINTS /////////////////

export const scheduleTask = async ({
  values,
  programUuid,
}: {
  values: any;
  programUuid: string;
}): Promise<AxiosResponse> => {
  return await axios
    .post(
      `${carrierApiUrl}/scheduler/programs/${programUuid}/tasks`,
      values,
      taskConfig,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const dispatchTask = async (
  taskUuid: string,
): Promise<AxiosResponse> => {
  return await axios
    .post(
      `${carrierApiUrl}/scheduler/${taskUuid}/dispatch`,
      { task_id: taskUuid },
      taskConfig,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const cancelTask = async (taskUuid: string): Promise<AxiosResponse> => {
  return await axios
    .delete(`${carrierApiUrl}/scheduler/${taskUuid}`, taskConfig)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const listScheduledTasks = async ({
  programUuid,
}: {
  programUuid: string;
}): Promise<AxiosResponse> => {
  return await axios
    .get(`${carrierApiUrl}/scheduler/programs/${programUuid}/tasks`, taskConfig)
    .then((response) => response)
    .catch((error) => {
      // toast.error(`listScheduledTasks failed, error details: ${error.message}`);
      throw error;
    });
};
