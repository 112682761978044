import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { QUERY_GET_CARRIERS, reassignCarrier } from '../../../queries';

type ReassignCarrierModalProps = {
  open: boolean;
  onClose: () => void;
  driverUuid: string;
  currentCarrierUuid: string;
  programUuid: string;
  onSuccess: () => void;
};

export function ReassignCarrierModal({
  open,
  onClose,
  driverUuid,
  currentCarrierUuid,
  programUuid,
  onSuccess,
}: ReassignCarrierModalProps) {
  const [selectedCarrier, setSelectedCarrier] = React.useState<string | null>(
    null,
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { data: carriersData, loading } = useQuery(QUERY_GET_CARRIERS, {
    variables: { programUuid },
  });

  const handleCarrierSelect = (carrierUuid: string) => {
    setSelectedCarrier(carrierUuid);
  };

  const handleSubmit = async () => {
    if (!selectedCarrier) return;

    setIsSubmitting(true);
    try {
      await reassignCarrier(selectedCarrier, driverUuid, currentCarrierUuid);
      toast.success('Driver reassigned successfully');
      onSuccess();
      onClose();
    } catch (error: any) {
      toast.error(`Failed to reassign driver: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const availableCarriers =
    carriersData?.getCarriers.filter(
      (carrier: any) => carrier.uuid !== currentCarrierUuid,
    ) || [];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Reassign Driver&apos;s Carrier</DialogTitle>
      <DialogContent>
        {loading ? (
          <div>Loading carriers...</div>
        ) : (
          <FormControl fullWidth margin="normal">
            <InputLabel id="carrier-select-label">Select Carrier</InputLabel>
            <Select
              labelId="carrier-select-label"
              id="carrier-select"
              value={selectedCarrier || ''}
              label="Select Carrier"
              onChange={(event) => handleCarrierSelect(event.target.value)}
            >
              {availableCarriers.map((carrier: any) => (
                <MenuItem key={carrier.uuid} value={carrier.uuid}>
                  {carrier.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={!selectedCarrier || isSubmitting}
          variant="contained"
          color="primary"
        >
          {isSubmitting ? 'Reassigning...' : 'Reassign'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
