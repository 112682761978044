import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import * as Yup from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import { addDriver } from '../../queries';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPasswordDriver } from '../CarrierDriversResetPassword/mutations';
import { generatePath } from '@/aggregator/paths/helpers';
import { PathPrivate } from '@/aggregator/paths/constants';
import toast from 'react-hot-toast';

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const validationSchemaObject = Yup.object().shape({
  driverExternalId: Yup.string().required('Required field'),
  carrierUuid: Yup.string().required('Required field'),
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  email: Yup.string().email().required('Required field'),
  phoneNumber: Yup.string()
    .matches(phoneRegex, 'Invalid phone number')
    .required('Required field'),
  password: Yup.string().required('Required field'),
  type: Yup.string().required('Required field'),
  startDate: Yup.date().nullable().required('Required field'),
});

const initialValues = {
  driverExternalId: '',
  carrierUuid: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  type: '',
  startDate: null,
  uuid: '',
  password: '',
};

const FORM_INPUT_FIELDS = [
  {
    value: 'driverExternalId',
    label: 'Driver External ID',
  },
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'phoneNumber',
    label: 'Phone Number',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'password',
    label: 'Password',
  },
];

export const CarrierAddDriver = () => {
  const { carrierId = '' } = useParams();
  const navigate = useNavigate();

  const handleClose = (shouldRefetch: boolean) => {
    const pathUpdated = generatePath(PathPrivate.CarrierDrivers, {
      carrierId: carrierId,
    });
    navigate(pathUpdated, { state: { shouldRefetch } });
  };

  return (
    <Formik
      initialValues={
        carrierId ? { ...initialValues, carrierUuid: carrierId } : initialValues
      }
      validationSchema={validationSchemaObject}
      onSubmit={(values) => {
        const startStr =
          values.startDate !== null ? format(values.startDate, 'yyyyMMdd') : '';
        addDriver(
          {
            ...values,
            startDate: startStr,
          },
          carrierId,
        )
          .then(
            (response: { status: number; data: { driver: { uuid: any } } }) => {
              if (response.status === 200) {
                resetPasswordDriver({
                  password: values.password,
                  temporary: true,
                  entityUuid: response.data.driver.uuid,
                })
                  .then(() => toast.success('Driver added'))
                  .catch(() =>
                    toast.error(
                      'Unable to add driver. Error setting password.',
                    ),
                  );
              }
            },
          )
          .catch((error) =>
            toast.error(`add driver failed, error details: ${error.message}`),
          )
          .finally(() => {
            handleClose(true);
          });
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        touched,
      }: any) => (
        <Dialog open onClose={() => handleClose(false)}>
          <DialogTitle>Add Driver</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              {FORM_INPUT_FIELDS.map((field) => {
                const { value, label } = field;
                if (value === 'phoneNumber') {
                  return (
                    <InputMask
                      key={`${value}-${label}`}
                      mask="(999) 999-9999"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {() => (
                        <TextField
                          key={`${value}-${label}`}
                          id={value}
                          label={label}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          sx={{ marginBottom: 1 }}
                          error={touched[value] && !!errors[value]}
                          helperText={touched[value] && errors[value]}
                        />
                      )}
                    </InputMask>
                  );
                }
                return (
                  <TextField
                    key={`${value}-${label}`}
                    id={value}
                    label={label}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    sx={{ marginBottom: 1 }}
                    error={touched[value] && !!errors[value]}
                  />
                );
              })}
              <Box mb={1}>
                <FormControl fullWidth>
                  <InputLabel id="type" error={touched.type && !!errors.type}>
                    Driver Type
                  </InputLabel>
                  <Select
                    labelId="driver type label"
                    name="type"
                    value={values.type}
                    label="Driver Type"
                    onChange={handleChange}
                    error={touched.type && !!errors.type}
                  >
                    <MenuItem value="Owner Operator">Owner Operator</MenuItem>
                    <MenuItem value="Company">Company</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={1}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={values.startDate}
                      onChange={(newValue) => {
                        setFieldValue('startDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={touched.startDate && !!errors.startDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={() => handleClose(false)}>
                Cancel
              </SecondaryButton>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
