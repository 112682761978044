import axios from 'axios';
import { Config } from '@/aggregator/config/env';

const apiUrl = Config.CARRIER_API_URL;

export async function updateUser({
  uuid,
  userId,
  values,
}: {
  uuid: string;
  userId: string;
  values: {
    newEmail: string;
    newFirstName: string;
    newLastName: string;
    phoneNumber: string;
    roles?: string | string[];
    role?: string;
  };
}) {
  return await axios
    .post(`${apiUrl}/programs/${uuid}/users/${userId}`, values)
    .then((response) => response)
    .catch((error) => error);
}
