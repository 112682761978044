import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  OutlinedInput,
  Chip,
  Autocomplete,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SecondaryButton, PrimaryButton } from '@/aggregator/ui/inputs/Button';
import { PathPrivate } from '@/aggregator/paths/constants';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';
import * as helpers from './helpers';
import { defaultEndDate, defaultStartDate } from '@/aggregator/helpers';
import { EXPORT_FILE_TYPES } from '@/aggregator/constants';
import { Mixpanel } from '@/aggregator/config/mixpanel';
import useGetDrivers from '@/aggregator/hooks/useGetDrivers';

export const PRODUCT_CATEGORIES = {
  DIESEL: 'PRODUCT_CATEGORY_DIESEL',
  REEFER: 'PRODUCT_CATEGORY_REEFER',
  DEF: 'PRODUCT_CATEGORY_DEF',
  OIL: 'PRODUCT_CATEGORY_OIL',
  ADDITIVES: 'PRODUCT_CATEGORY_ADDITIVES',
  MERCHANDISE: 'PRODUCT_CATEGORY_MERCHANDISE',
  CASH_ADVANCE: 'PRODUCT_CATEGORY_CASH_ADVANCE',
};

const categories = [
  { value: PRODUCT_CATEGORIES.DIESEL, label: 'Diesel' },
  { value: PRODUCT_CATEGORIES.REEFER, label: 'Reefer' },
  { value: PRODUCT_CATEGORIES.DEF, label: 'Def' },
  { value: PRODUCT_CATEGORIES.OIL, label: 'Oil' },
  { value: PRODUCT_CATEGORIES.ADDITIVES, label: 'Additives' },
  { value: PRODUCT_CATEGORIES.MERCHANDISE, label: 'Merchandise' },
  { value: PRODUCT_CATEGORIES.CASH_ADVANCE, label: 'Cash Advance' },
];

export function getByValue(typeValue: string) {
  return categories.find((x) => x.value === typeValue);
}

export function CarrierExportTransactions() {
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [driverUuid, setDriverUuid] = useState<string>('');
  const [unitNumber, setUnitNumber] = useState<string>('');
  const [retailOnly, setRetailOnly] = useState<boolean>(false);

  const navigate = useNavigate();
  const client = useApolloClient();
  const { carrierId = '' } = useParams();

  const carrier = queriesCarriers.getCarrierFromFragment(client, carrierId);
  const { drivers } = useGetDrivers(carrierId);

  const handleOnClose = () => {
    navigate(PathPrivate.Carriers);
  };

  const handleSelectProduct = (value: string | string[]) => {
    if (Array.isArray(value)) {
      setSelectedCategories(value);
    }
  };

  if (!carrier) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        exportFileType: EXPORT_FILE_TYPES.PDF.value,
      }}
      onSubmit={(values) => {
        helpers
          .exportTransactions(
            carrier.uuid,
            startDate,
            endDate,
            values.exportFileType,
            selectedCategories,
            driverUuid,
            unitNumber,
            retailOnly,
          )
          .then(() => {
            Mixpanel.track('report_export_success', {
              location: location.pathname,
            });
          })
          .catch((e) =>
            Mixpanel.track('report_export_failed', {
              location: location.pathname,
              error: e,
            }),
          );
        handleOnClose();
      }}
    >
      {({ handleSubmit, handleChange, values }) => (
        <Dialog open onClose={handleOnClose} maxWidth="md" fullWidth>
          <DialogTitle>
            <Typography variant="h6">Export Report</Typography>
          </DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box sx={{ flex: 1 }}>
                      <DatePicker
                        label="Start Date"
                        maxDate={endDate}
                        value={startDate}
                        onChange={(newValue) => {
                          isValid(newValue) && setStartDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" fullWidth {...params} />
                        )}
                      />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <TimePicker
                        label="Start Time"
                        value={startDate}
                        onChange={(newValue) => {
                          isValid(newValue) && setStartDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" fullWidth {...params} />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box sx={{ flex: 1 }}>
                      <DatePicker
                        label="End Date"
                        minDate={startDate}
                        value={endDate}
                        onChange={(newValue) => {
                          isValid(newValue) && setEndDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" fullWidth {...params} />
                        )}
                      />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <TimePicker
                        label="End Time"
                        value={endDate}
                        onChange={(newValue) => {
                          isValid(newValue) && setEndDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" fullWidth {...params} />
                        )}
                      />
                    </Box>
                  </Box>
                </LocalizationProvider>

                <FormControl fullWidth>
                  <InputLabel>File Type</InputLabel>
                  <Select
                    value={values.exportFileType}
                    label="File Type"
                    onChange={handleChange}
                    name="exportFileType"
                  >
                    <MenuItem value={EXPORT_FILE_TYPES.CSV.value}>CSV</MenuItem>
                    <MenuItem value={EXPORT_FILE_TYPES.PDF.value}>PDF</MenuItem>
                    <MenuItem value={EXPORT_FILE_TYPES.XLS.value}>XLS</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>
                    Included Product Categories (optional)
                  </InputLabel>
                  <Select
                    multiple
                    value={selectedCategories}
                    onChange={(e) => handleSelectProduct(e.target.value)}
                    input={
                      <OutlinedInput label="Included Product Categories (optional)" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                          const category = getByValue(value);
                          return category ? (
                            <Chip key={value} label={category.label} />
                          ) : null;
                        })}
                      </Box>
                    )}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.value} value={category.value}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {drivers && (
                  <Autocomplete
                    options={drivers}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    onChange={(_, driver) => {
                      setDriverUuid(driver?.uuid || '');
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Driver (optional)" />
                    )}
                  />
                )}

                <TextField
                  label="Unit Number (optional)"
                  value={unitNumber}
                  onChange={(e) => setUnitNumber(e.target.value)}
                  fullWidth
                />

                {values.exportFileType === EXPORT_FILE_TYPES.PDF.value && (
                  <FormControl>
                    <label>
                      <input
                        type="checkbox"
                        checked={retailOnly}
                        onChange={(e) => setRetailOnly(e.target.checked)}
                        style={{ marginRight: '8px' }}
                      />
                      Retail Only
                    </label>
                  </FormControl>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleOnClose}>Cancel</SecondaryButton>
              <PrimaryButton
                type="submit"
                disabled={Boolean(!startDate || !endDate)}
              >
                Export
              </PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
