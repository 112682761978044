export namespace CarrierStatus {
  const statusTypes = [
    {
      name: 'Active',
      value: 'CARRIER_STATUS_ACTIVE',
      pill: {
        color: 'green',
      },
    },
    {
      name: 'Inactive',
      value: 'CARRIER_STATUS_INACTIVE',
      pill: {
        color: '#636363',
      },
    },
    {
      name: 'Suspended',
      value: 'CARRIER_STATUS_SUSPENDED',
      pill: {
        color: '#c76e00',
      },
    },
    {
      name: 'Deleted',
      value: 'CARRIER_STATUS_DELETED',
      pill: {
        color: '#cc0000',
      },
    },
  ];
  export function getByValue(typeValue: string) {
    return statusTypes.find((x) => x.value === typeValue);
  }
}
