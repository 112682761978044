import Keycloak from 'keycloak-js';

import { Config } from '@/aggregator/config/env';

const keycloakClient = new Keycloak({
  url: `${Config.AUTH_URL}/auth`,
  realm: 'app',
  clientId: 'web',
});

enum UserRole {
  ProgramManager = 'program_manager',
  FleetManager = 'fleet_manager',
}

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
export const initKeycloak = (onAuthenticatedCallback: () => void) => {
  keycloakClient
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      pkceMethod: 'S256',
    })
    .then((authenticated: any) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

export const doLogin = keycloakClient.login;

export const doLogout = keycloakClient.logout;

export const isLoggedIn = () => Boolean(keycloakClient.token);

export const getToken = () => keycloakClient.token;

export const getUserEmail = () => {
  return keycloakClient.tokenParsed?.email;
};

export function isProgramManager() {
  return keycloakClient.hasRealmRole(UserRole.ProgramManager);
}

export function isFleetManager() {
  return keycloakClient.hasRealmRole(UserRole.FleetManager);
}

export function getProgramUuid(): string {
  return keycloakClient.tokenParsed?.program_uuid || '';
}
