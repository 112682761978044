export const EXPORT_FILE_TYPES = {
  CSV: {
    value: 'FILE_TYPE_CSV',
    name: 'csv',
  },
  PDF: {
    value: 'FILE_TYPE_PDF',
    name: 'pdf',
  },
  XLS: {
    value: 'FILE_TYPE_XLS',
    name: 'xls',
  },
};

export enum UserRoles {
  DRIVER = 'Driver',
  FLEET_MANAGER = 'Fleet Manager',
  PROGRAM_MANAGER = 'Program Manager',
}

export const USER_ROLES = [
  {
    role: 'DRIVER',
    label: UserRoles.DRIVER,
  },
  {
    role: 'FLEET_MANAGER',
    label: UserRoles.FLEET_MANAGER,
  },
  {
    role: 'PROGRAM_MANAGER',
    label: UserRoles.PROGRAM_MANAGER,
  },
];
