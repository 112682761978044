import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import toast from 'react-hot-toast';
import * as auth from '@/aggregator/config/auth';
import { PathPrivate } from '@/aggregator/paths/constants';
import { SecondaryButton, PrimaryButton } from '@/aggregator/ui/inputs/Button';
import * as mutations from './mutations';

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const validationSchemaObject = Yup.object().shape({
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  email: Yup.string().email().required('Required field'),
  phoneNumber: Yup.string()
    .matches(phoneRegex, 'Invalid phone number')
    .required('Required field'),
  password: Yup.string().required('Required field'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
};

const FORM_INPUT_FIELDS = [
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'phoneNumber',
    label: 'Phone Number',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'password',
    label: 'Password',
  },
];

export function AddUser() {
  const programUuid = auth.getProgramUuid();
  const navigate = useNavigate();

  const handleOnClickClose = (shouldRefetch: boolean) => {
    navigate(PathPrivate.Users, { state: { shouldRefetch } });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaObject}
      onSubmit={(values: any) => {
        const payloadValues = {
          user: {
            ...values,
            role: 'PROGRAM_MANAGER',
          },
          programUuid: programUuid,
        };

        mutations
          .addUser(programUuid, payloadValues)
          .then((response) => {
            if (response.status === 200) {
              mutations
                .setPassword({
                  password: values.password,
                  temporary: true,
                  entityUuid: response.data.user.entityUuid,
                  identityUuid: response.data.user.identityUuid,
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            }
          })
          .catch((error) => {
            toast.error(error.message);
          })
          .finally(() => {
            handleOnClickClose(true);
          });
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
      }) => (
        <Dialog open onClose={() => handleOnClickClose(false)}>
          <DialogTitle>Add User</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              {FORM_INPUT_FIELDS.map((field) => {
                const { value, label } = field;
                if (value === 'phoneNumber') {
                  return (
                    <InputMask
                      key={`${value}-${label}`}
                      mask="(999) 999-9999"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {() => (
                        <TextField
                          key={`${value}-${label}`}
                          id={value}
                          label={label}
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          sx={{ marginBottom: 1 }}
                          error={touched[value] && !!errors[value]}
                        />
                      )}
                    </InputMask>
                  );
                }
                return (
                  <TextField
                    key={`${value}-${label}`}
                    id={value}
                    label={label}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    sx={{ marginBottom: 1 }}
                  />
                );
              })}
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={() => handleOnClickClose(false)}>
                Cancel
              </SecondaryButton>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
