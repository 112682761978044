import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';

import DownloadIcon from '@mui/icons-material/Download';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { convertTimeToTz } from '../CarrierExportTransactions/helpers';
import { listTransactionsByProgramService } from '../../queries';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import { getProgramUuid } from '@/aggregator/config/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathPrivate } from '@/aggregator/paths/constants';
import { Mixpanel } from '@/aggregator/config/mixpanel';
import { defaultEndDate, defaultStartDate } from '@/aggregator/helpers';

const exportTransactions = async (
  programUuid: string,
  startDate: Date | null,
  endDate: Date | null,
) => {
  const dupeStartDate = startDate ? new Date(startDate) : null;
  const dupeEndDate = endDate ? new Date(endDate) : null;

  const sDate =
    dupeStartDate !== null ? convertTimeToTz(dupeStartDate).toISOString() : '';
  const eDate =
    dupeEndDate !== null ? convertTimeToTz(dupeEndDate).toISOString() : '';

  listTransactionsByProgramService(programUuid, sDate, eDate)
    .then(async (res) => {
      const data = res.data;
      const rawBytes = Uint8Array.from(atob(data.raw), (c) => c.charCodeAt(0));
      const blob = new Blob([rawBytes], { type: 'FILE_TYPE_CSV' });
      const fileName = data.filename;

      // Create URL and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((e: any) => {
      if (e.response) {
        toast.error(e.response.data.message);
      } else {
        toast.error(e.message);
      }
    });
};

const dateRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginY: 1,
  paddingX: 1,
};

export const CarrierExportAllProgramTransactions: FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);
  const navigate = useNavigate();
  const location = useLocation();

  const programUuid = getProgramUuid() || '';

  const handleClose = () => {
    navigate(PathPrivate.Carriers);
  };

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={() => {
        exportTransactions(programUuid, startDate, endDate)
          .then(() => {
            Mixpanel.track('report_exported', { location: location.pathname });
          })
          .catch((e) =>
            Mixpanel.track('report_export_failed', {
              location: location.pathname,
              error: e,
            }),
          );
        handleClose();
      }}
    >
      {({ handleSubmit }) => (
        <Dialog open onClose={handleClose}>
          <DialogTitle>Export Program Transactions</DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Box mt={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box sx={dateRowStyle}>
                    <DatePicker
                      label="Start Date"
                      maxDate={endDate}
                      value={startDate}
                      onChange={(newValue) => {
                        isValid(newValue) && setStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{ mb: 1, mr: 0.5 }}
                        />
                      )}
                    />
                    <TimePicker
                      label="Start Time"
                      value={startDate}
                      onChange={(newValue) => {
                        isValid(newValue) && setStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Box>
                  <Box sx={dateRowStyle}>
                    <DatePicker
                      label="End Date"
                      minDate={startDate}
                      value={endDate}
                      onChange={(newValue) => {
                        isValid(newValue) && setEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{ mb: 1, mr: 0.5 }}
                        />
                      )}
                    />
                    <TimePicker
                      label="End Time"
                      value={endDate}
                      onChange={(newValue) => {
                        isValid(newValue) && setEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
              <PrimaryButton type="submit" startIcon={<DownloadIcon />}>
                Export
              </PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
