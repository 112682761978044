import { gql } from '@apollo/client';

export const QUERY_GET_DASHBOARD_INFO = gql`
  query GetDashboardInfo($programUuid: String!, $timeFrame: DashBoardTime!) {
    getDashboardInfo(
      parentUuid: $programUuid
      timeFrame: $timeFrame
      entity: PROGRAM
    ) {
      activePump
      totalGallons
      totalDollars
      totalDiscounted
      mostActiveLocations
      activeCarrier
      totalTransactions
      savings
      dataPoints {
        time
        dollar
        gallon
      }
    }
  }
`;
