import {
  Box,
  Dialog,
  Typography,
  FormControl,
  TextField,
  CircularProgress,
  Select,
  InputLabel,
  Chip,
  MenuItem,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { generatePath } from '@/aggregator/paths/helpers';
import { PathPrivate } from '@/aggregator/paths/constants';
import { Formik, Form } from 'formik';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import { useEffect, useState } from 'react';
import * as types from '../CarrierFleetAppUsers/types';
import * as queries from '../CarrierFleetAppUsers/queries';
import { PatternFormat } from 'react-number-format';
import * as Yup from 'yup';
import * as auth from '@/aggregator/config/auth';
import { updateUser } from './queries';
import toast from 'react-hot-toast';
import { USER_ROLES, UserRoles } from '@/aggregator/constants';

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  email: Yup.string().email('Invalid email address').required('Required field'),
  phoneNumber: Yup.string().optional(),
});

export const CarrierFleetAppEditUser = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<types.UserType | undefined>(undefined);
  const navigate = useNavigate();
  const { carrierId = '', userId = '' } = useParams();
  const programUuid = auth.getProgramUuid();

  const handleOnClose = () => {
    const pathUpdated = generatePath(PathPrivate.CarrierFleetAppUsers, {
      carrierId,
    });

    navigate(pathUpdated);
  };

  const getData = async () => {
    if (!loading) {
      setLoading(true);
    }
    try {
      const response: types.UsersResponseType =
        await queries.getUsers(carrierId);
      const user = response.users.find((user) => user.identityUuid === userId);
      setUser(user);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (carrierId && userId) {
      getData();
    }
  }, [carrierId, userId]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Formik
          initialValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            roles: user?.roles || [''],
            phoneNumber: user?.phoneNumber || '',
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values) => {
            const payloadValues = {
              newEmail: values.email,
              newFirstName: values.firstName,
              newLastName: values.lastName,
              phoneNumber: values.phoneNumber,
              roles: values.roles,
              carrierUuid: carrierId,
            };
            try {
              const response = await updateUser({
                uuid: programUuid,
                userId: user?.entityUuid || '',
                values: payloadValues,
              });
              if (response.status === 200) {
                toast.success('User updated successfully!');
              } else {
                const errorMessage =
                  response?.data?.message || 'Unknown error occurred.';
                toast.error(`Unable to update user. Error: ${errorMessage}`);
              }
            } catch (error) {
              toast.error('Unable to update user');
            }
            handleOnClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <Dialog open onClose={handleOnClose}>
              <Form>
                <Box m={2} minWidth={400}>
                  <Typography variant="h5" mb={2}>
                    Edit User
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    <FormControl sx={{ mb: 2 }} fullWidth>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        label="First Name"
                        placeholder="First Name"
                        name="firstName"
                        fullWidth
                        error={touched.firstName && Boolean(errors.firstName)}
                      />
                      {touched.firstName && errors.firstName && (
                        <Typography variant="caption" color="error">
                          {errors.firstName}
                        </Typography>
                      )}
                    </FormControl>

                    <FormControl sx={{ mb: 2 }} fullWidth>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        label="Last Name"
                        placeholder="Last Name"
                        name="lastName"
                        fullWidth
                        error={touched.lastName && Boolean(errors.lastName)}
                      />
                      {touched.lastName && errors.lastName && (
                        <Typography variant="caption" color="error">
                          {errors.lastName}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl sx={{ mb: 2 }} fullWidth>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        name="email"
                        label="Email"
                        placeholder="Email"
                        fullWidth
                        error={touched.email && Boolean(errors.email)}
                        sx={{
                          '& .MuiInputBase-input': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          },
                        }}
                      />
                      {touched.email && errors.email && (
                        <Typography variant="caption" color="error">
                          {errors.email}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl sx={{ mb: 2 }} fullWidth>
                      <PatternFormat
                        customInput={TextField}
                        format="###-###-####"
                        mask="_"
                        name="phoneNumber"
                        label="Phone Number (Optional)"
                        value={values.phoneNumber}
                        onBlur={handleBlur}
                        onValueChange={(values) => {
                          setFieldValue('phoneNumber', values.formattedValue);
                        }}
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        fullWidth
                      />
                      {touched.phoneNumber && errors.phoneNumber && (
                        <Typography variant="caption" color="error">
                          {errors.phoneNumber}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      error={touched.roles && !!errors.roles}
                    >
                      <InputLabel>User Roles</InputLabel>
                      <Select
                        label="User Roles"
                        multiple
                        value={values.roles}
                        onChange={async (event) => {
                          const selectedRoles = event.target.value;
                          setFieldValue('roles', selectedRoles);
                        }}
                        renderValue={(selected) => (
                          <div style={{ display: 'flex', gap: 4 }}>
                            {selected.map((role: string) => {
                              if (role === UserRoles.PROGRAM_MANAGER) return;
                              return (
                                <Chip
                                  key={role}
                                  label={
                                    UserRoles[role as keyof typeof UserRoles]
                                  }
                                />
                              );
                            })}
                          </div>
                        )}
                      >
                        {USER_ROLES.map((role) => {
                          if (role.label === UserRoles.PROGRAM_MANAGER) return;
                          return (
                            <MenuItem key={role.role} value={role.role}>
                              {role.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box my={1} display="flex" justifyContent="flex-end">
                    <SecondaryButton onClick={handleOnClose}>
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                  </Box>
                </Box>
              </Form>
            </Dialog>
          )}
        </Formik>
      )}
    </>
  );
};
